import React from 'react'
import { Link } from 'gatsby'

import Seo from '../components/Seo'
import BlogCard from '../components/mainComponents/BlogCard/BlogCard'
import { filteredPostsConfigurator } from '../helpers/helpersForBlog'

import * as styles from '../pages/blog/styles.module.scss'

const BlogCategoryPage = ({ pageContext }) => {
  const posts = filteredPostsConfigurator(pageContext.data.filteredArr)
  const category = pageContext.data.category

  const isThemeEqual = (text) => {
    return category === text.toLowerCase().replace(/\s/g, '')
  }

  const createThemeText = (text) => {
    return text.slice(0, 1).toUpperCase() + text.slice(1)
  }

  const onLinkClick = (text) => {
    return text === category ? '/blog' : `/blog/${text}/`
  }

  return (
    <>
      <Seo
        title={'Justice | Blog'}
        description="Justice - We take care of our staff and clients. Let us show who we are."
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
      />
      <div className={styles.blog}>
        <div className="container">
          <div className={styles.blogTop}>
            <h2 className={styles.blogTitle}>Blog</h2>
            <div className={styles.blogThemes}>
              {pageContext.data.set.map((text, id) => {
                return (
                  <Link
                    to={onLinkClick(text)}
                    className={
                      isThemeEqual(text)
                        ? `${styles.blogThema} ${styles.blogThemaActive}`
                        : styles.blogThema
                    }
                    key={id}
                  >
                    {createThemeText(text)}
                  </Link>
                )
              })}
            </div>
          </div>
          <div className={styles.blogInner}>
            {posts.map((elem) => (
              <BlogCard
                key={elem.href + elem.title}
                title={elem.title}
                titleRu={elem.titleRu}
                href={elem.href}
                src={elem.imgSrc}
                date={elem.data}
                text={elem.description}
                textRu={elem.descriptionRu}
                type={elem.category}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogCategoryPage
